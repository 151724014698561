import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledProtectionDddosCloudBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50, 70]
  })
)

export const styledProtectionDddosCloud = css`
  ${styledProtectionDddosCloudBps};
`

/* HEADER */
export const H4Bps = css(
  mq({
    width: [328, '100%', 680, 680],
    paddingBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const H5Bps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginTop: [40],
    marginBottom: [30],
    letterSpacing: [0.25],
    textAlign: ['center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const PContentBps = css(
  mq({
    maxWidth: [242, 336, 336, 336]
  })
)

export const contentItemBps = css(
  mq({
    marginBottom: [30, 22, 22, 22]
  })
)

export const IdProtectBoxBps = css(
  mq({
    width: [328, 422, 680, 680]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4Bps};
    color: rgba(0, 0, 0, 0.87);
    margin: 0 auto;
  }
  h5 {
    ${H5Bps};
    color: rgba(0, 0, 0, 0.87);
    margin: 0 auto;
  }
  p {
    ${PHeaderBps};
    margin: 0 auto;
  }
  img {
    width: 244px;
  }
`

export const IdProtectBox = css`
  ${IdProtectBoxBps};
  margin: 0 auto !important;
`

export const IdProtectList = css`
  display: flex;
  align-items: end;
  margin-bottom: 16px;
  img {
    margin-right: 16px;
  }
  span {
    font-size: 15.7px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }
`