import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'
import ds from '../theme'
import { css } from '@emotion/core'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import Hr from '../components/Hr'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import SubTitle from '../components/SubTitle'
import Button from '../components/Button/Button'
import Ilustration1 from '../images/protection-ddos-premium-1.png'
import Arrow from '../images/arrow-forward-24-px.svg'

import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styledProtectionDddosCloud,
  header,
  IdProtectList,
  IdProtectBox
} from '../components/styles/ProtectionDDOSPremium.styles'

const DcItem = ({
  title,
  text,
  protection1Gb,
  protection1GbValue,
  protection3Gb,
  protection3GbValue,
  protection5Gb,
  protection5GbValue,
  customSetting,
  customSettingDesc,
  example,
  exampleDesc,
  ctaText,
}) => {
  return (
    <div
      style={{
        padding: '24px 24px 40px 24px',
        backgroundColor: '#f9fafa',
        borderRadius: 16,
        transition: 'height 0.2s'
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='column'
        alignItems='left'
      >
        <H5
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            width: 280,
            letterSpacing: 0.25,
            marginBottom: 8
          }}
        >
          {title}
        </H5>
        <Div 
          style={{ 
            fontSize: 13.8,
            fontWeight: 500,
            lineHeight: 1.45,
            letterSpacing: 0.1,
            color: 'rgba(0, 0, 0, 0.6)',
            marginBottom: 23.5
          }}
        >
          {text}
        </Div>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            marginBottom: 22.5
          }}
        />
        <Paragraph
          style={{
            lineHeight: 1.43,
            color: 'rgba(0, 0, 0, 0.87)',
            marginBottom: 8,
            whiteSpace: 'pre-wrap'
          }}
        >
          {protection1Gb}
        </Paragraph>
        <Paragraph
          style={{
            lineHeight: 1.29,
            fontWeight: 600,
            color: 'rgba(0, 0, 0, 0.87)',
            marginBottom: 16,
            whiteSpace: 'pre-wrap'
          }}
        >
          {protection1GbValue}
        </Paragraph>
        <Paragraph
          style={{
            lineHeight: 1.43,
            color: 'rgba(0, 0, 0, 0.87)',
            marginBottom: 8,
            whiteSpace: 'pre-wrap'
          }}
        >
          {protection3Gb}
        </Paragraph>
        <Paragraph
          style={{
            lineHeight: 1.29,
            fontWeight: 600,
            color: 'rgba(0, 0, 0, 0.87)',
            marginBottom: 16,
            whiteSpace: 'pre-wrap'
          }}
        >
          {protection3GbValue}
        </Paragraph>
        <Paragraph
          style={{
            lineHeight: 1.43,
            color: 'rgba(0, 0, 0, 0.87)',
            marginBottom: 8,
            whiteSpace: 'pre-wrap'
          }}
        >
          {protection5Gb}
        </Paragraph>
        <Paragraph
          style={{
            lineHeight: 1.29,
            fontWeight: 600,
            color: 'rgba(0, 0, 0, 0.87)',
            marginBottom: 16,
            whiteSpace: 'pre-wrap'
          }}
        >
          {protection5GbValue}
        </Paragraph>
        <SubTitle
          style={{
            fontWeight: 600,
            lineHeight: 1.53,
            letterSpacing: 0.15,
            color: 'rgba(0, 0, 0, 0.87)',
            marginTop: 36,
            marginBottom: 8
          }}
        >
          {customSetting}
        </SubTitle>
        <Paragraph
          style={{
            lineHeight: 1.43,
            color: 'rgba(0, 0, 0, 0.87)',
            marginBottom: 20,
            whiteSpace: 'pre-wrap'
          }}
        >
          {customSettingDesc}
        </Paragraph>
        <SubTitle
          style={{
            fontWeight: 600,
            lineHeight: 1.53,
            letterSpacing: 0.15,
            color: 'rgba(0, 0, 0, 0.87)',
            marginBottom: 8
          }}
        >
          {example}
        </SubTitle>
        <Paragraph
          style={{
            lineHeight: 1.71,
            color: 'rgba(0, 0, 0, 0.87)',
            marginBottom: 30,
            whiteSpace: 'pre-wrap'
          }}
        >
          {exampleDesc}
        </Paragraph>
        <Button
          schema='white'
          outlined
          selected
          variant='base'
          on='onSurface'
          width={['100%']}
          maxWidth={['150px']}
          style={{
            display: 'flex',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
            margin: '0 auto',
            backgroundColor: '#ffffff'
          }}
        >
          {ctaText}
        </Button>
      </Div>
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledProtectionDddosCloud}>
        <Container>
          <Row
            flexDirection={['column']}
            css={header}
            mb={30}
          >
            <Column
              width={[1]}
              mb={30}
            >
              <H4>
                <span>PROTEÇÃO DDOS PREMIUM</span><br />
                Proteção e Segurança DDoS Premium
              </H4>
              <Paragraph>Com a Proteção DDoS Premium, todo o tráfego suspeito será analisado, em busca de atividades maliciosas, antes de direcionar este acesso ao seu servidor com baixíssima ou nenhuma alteração na velocidade de acesso ao servidor.</Paragraph>
            </Column>
            <Column
              mb={30}
            >
              <H5>Sem Proteção e Segurança DDoS Premium da HostDime Brasil</H5>
            </Column>
            <Div
              flexDirection={['column', 'row']}
              justifyContent={['center']}
              alignItems={['center']}
            >
              <img src={Ilustration1} />
              <img src={Ilustration1} />
            </Div>
            <Column
              width={[1]}
              mt={30}
            >
              <Paragraph>Sem Proteção DDoS Premium, um ataque de grande proporção pode saturar a capacidade de rede do seu servidor deixando-o indisponível por conta do volume de tráfego malicioso recebido, não deixando espaço para que o tráfego legítimo flua.</Paragraph>
            </Column>
            <Hr
              lineColor='lightPeriwinkle'
              maxWidth={[328, '100%' ,680, 680]}
              style={{
                opacity: 0.5,
                margin: '0 auto',
                marginTop: 45.5,
                marginBottom: 39.5
              }}
            />
            <Column>
              <H5 style={{ marginBottom: 16 }}>A Proteção Premium da HostDime é integrada à rede de nosso DataCenter.</H5>
              <Paragraph style={{ marginBottom: 16 }}>Com ela, tanto o tráfego legítimo quando o ilegítimo entram em nossa rede da mesma forma e, só então, o tráfego ilegítimo é filtrado, deixando apenas o legítimo fluir para o seu servidor. Por isso, você só perceberá uma baixíssima ou mesmo nenhuma latência adicional quando a proteção estiver em ação.</Paragraph>
              <Paragraph style={{ marginBottom: 16 }}>Uma vez que o seu servidor passa a ser monitorado por nosso serviço, o sistema de proteção começa a aprender o tráfego normal de seu servidor, sendo capaz de detectar de maneira mais eficiente o tráfego como malicioso e filtrá-lo. Além desse aprendizado automatizado, nossos Engenheiros de Redes poderão também criar regras personalizadas para filtrar quaisquer ameaças globais conhecidas.</Paragraph>
              <Paragraph>A Arbor Networks, empresa líder em sistemas de proteção contra ataques DDoS, provê a HostDime em tempo real todas as informações referentes às ameaças globais a partir do programa ATLAS (Active Threat Level Analysis System).</Paragraph>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
            mb={[40, 50]}
          >
            <Column
              mb={[16, 16, 16, 0]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <DcItem
                  title='Assinaturas Mensais'
                  text='Monitoramento DDoS gratuito 24/7/365, Entrada - Saída'
                  protection1Gb={`Proteção contra ataques\nDDos com até 1Gbps`}
                  protection1GbValue='R$ 180,00/mensais'
                  protection3Gb={`Proteção contra ataques DDos\ncom até 3Gbps`}
                  protection3GbValue='R$ 360,00/mensais'
                  protection5Gb={`Proteção contra ataques DDos\ncom até 5Gbps`}
                  protection5GbValue='R$ 720,00/mensais'
                  customSetting='Configuração Personalizada'
                  customSettingDesc='Proteção contra ataques DDoS acima de 5Gbps será cobrado R$ 1,00 por 1Mbps'
                  example='Exemplo:'
                  exampleDesc={`Proteção contra ataques DDos com até\n5Gbps R$ 720,00/mensais\nRecebeu um ataque de até 6Gbps\nVocê pagará R$1,00 por 1Mbps, portanto\n6Gbps: 720,00 + 1.000,00 = R$ 1.720,00`}
                  ctaText='Consulte'
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 16, 0]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <DcItem
                  title='Sob Demanda'
                  text='Monitoramento DDoS gratuito 24/7/365, Entrada - Saída'
                  protection1Gb={`Proteção contra ataques\nDDos com até 1Gbps`}
                  protection1GbValue='R$ 180,00/mensais'
                  protection3Gb={`Proteção contra ataques DDos\ncom até 3Gbps`}
                  protection3GbValue='R$ 360,00/mensais'
                  protection5Gb={`Proteção contra ataques DDos\ncom até 5Gbps`}
                  protection5GbValue='R$ 720,00/mensais'
                  customSetting='Configuração Personalizada'
                  customSettingDesc='Proteção contra ataques DDoS acima de 5Gbps será cobrado R$ 1,00 por 1Mbps'
                  example='Exemplo:'
                  exampleDesc={`Proteção contra ataques DDos com até\n5Gbps R$ 720,00/mensais\nRecebeu um ataque de até 6Gbps\nVocê pagará R$1,00 por 1Mbps, portanto\n6Gbps: 720,00 + 1.000,00 = R$ 1.720,00`}
                  ctaText='Consulte'
                />
              </Div>
            </Column>
          </Row>
          <Row
            css={header}
            justifyContent={['center']}
            mb={40}
          >
            <Column>
              <H5>Com o Sistema PeakFlow, poderemos determinar:</H5>
            </Column>
          </Row>
          <Row>
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              css={IdProtectBox}>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={IdProtectList}>
                  <img src={Arrow} />
                  <span>Origem e Destino dos tráfegos no DimeNOC</span>
                </div>
                <div css={IdProtectList}>
                  <img src={Arrow} />
                  <span>Quais rotas o tráfego está utilizando</span>
                </div>
                <div css={IdProtectList}>
                  <img src={Arrow} />
                  <span>Quais interfaces e dispositivos são mais utilizados na rede do DimeNOC</span>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={IdProtectList}>
                  <img src={Arrow} />
                  <span>Quem são os principais utilizadores de tráfegos na rede o DimeNOC</span>
                </div>
                <div css={IdProtectList}>
                  <img src={Arrow} />
                  <span>Quais as tendências de utilização destes tráfegos a curto e longo prazo</span>
                </div>
                <div css={IdProtectList}>
                  <img src={Arrow} />
                  <span>Previsão atual e futura de uso dos tráfegos</span>
                </div>
              </Column>
            </Div>
          </Row>
          <Hr
            lineColor='lightPeriwinkle'
            maxWidth={[328, '100%' ,680, 680]}
            style={{
              opacity: 0.5,
              margin: '0 auto',
              marginTop: 30,
              marginBottom: 39.5
            }}
          />
          <Row
            css={header}
            justifyContent={['center']}
            flexDirection={'column'}
          >
            <Column
              mb={30}
            >
              <H5>Entre em contato conosco e aumente<br /> a proteção da sua empresa.</H5>
            </Column>
            <Button
              schema='secondary'
              color='white'
              variant='highEmphasis'
              width={['100%']}
              maxWidth={['328px']}
              style={{
                display: 'flex',
                border: 'none',
                margin: '0 auto',
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
              }}
            >
              Fale com nossos consultores
            </Button>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Proteção e Segurança DDoS Premium' />
      <PageTemplate
        title='Proteção e Segurança DDoS Premium'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
